//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    name: "GroupBulletin",
    props:['id','description', 'isAdmin', 'isMember', 'isOwner'],
    data() {
      return {
        isEditing: false
      }
    },
    methods: {
      save() {
        this.$q.dialog({
          message: 'Do you want to update the bulletin board?',
          title: "Save Bulletin",
          cancel: true,
          persistent: true
        }).onOk(() => {
          let bulletin = new FormData();
          bulletin.append('description', this.description)
          this.isEditing = false;
          this.$http({
            url: 'entities/' + this.id,
            method: 'POST',
            data: bulletin
          }).then((response) => {
            if (response.data['status'] == 'save_success') {
              this.$q.notify({
                message: 'The bulletin has been updated successfully.',
                color: 'primary'
              });
            }
          })
        }).onCancel(() => {
          //
        })
      }
    }
}
